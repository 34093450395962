import React, {useEffect, useState} from 'react';
import {ITheme, Model, SurveyModel} from 'survey-core';
import 'survey-core/defaultV2.min.css';
import {Survey} from 'survey-react-ui';
import {isInterstitial} from '../../screens/Interstitials/utils';
import {RiseHeader} from '../RiseHeader/RiseHeader';
import RiseSurveyControls, {RiseSurveyControlsProps} from '../RiseSurveyControls/RiseSurveyControls';
import {SegmentedProgressBar} from '../SegmentedProgressBar/SegmentedProgressBar';
import './RiseSurvey.css';
import surveyTheme from '../../commonAssets/surveyTheme.json';
import {SurveyData} from './SurveyData';
import {registerRiseInterstitial} from '../../screens/Interstitials/RiseInterstitialBase';
import {riseSurveyTheme} from './RiseSurveyTheme';
import Analytics from '../../utils/Analytics';

/**
 * Setup SurveyJS instance
 */
export const surveyInstance = new Model();
surveyInstance.css = riseSurveyTheme;
surveyInstance.applyTheme(surveyTheme as ITheme);

registerRiseInterstitial();

type RiseSurveyProps = {
  surveyConfigJson: any;
};

/**
 * This is the heart of the Rise Survey system.
 * It sets up the survey, the header, and handles the controls.
 */

export const RiseSurvey: React.FC<RiseSurveyProps> = ({surveyConfigJson}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const [isLastPage, setIsLastPage] = useState(false);
  const [isInterstitialPage, setIsInterstitialPage] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState(false);

  const buttonMode: RiseSurveyControlsProps['mode'] = isLastPage
    ? 'last_page'
    : isInterstitialPage
      ? 'interstitial'
      : 'question';

  useEffect(() => {
    Analytics.track('Survey Started', {
      survey_name: surveyInstance.title,
      abValue: localStorage.getItem('ab'),
      surveyTitle: surveyInstance.title
    });
  }, [surveyConfigJson]);

  useEffect(() => {
    const handleCurrentPageChanged = (sender: SurveyModel) => {
      const page = sender.currentPage;
      const isLastPage = sender.isLastPage;
      const isFirstPage = sender.isFirstPage;
      setIsLastPage(isLastPage);
      setIsFirstPage(isFirstPage);
      setIsInterstitialPage(isInterstitial(page));
      setCurrentQuestionIndex(sender.currentPageNo);
    };

    const setInitialSurveyState = (sender: SurveyModel) => {
      const page = sender.currentPage;
      const isLastPage = sender.isLastPage;
      const isFirstPage = sender.isFirstPage;
      setIsFirstPage(isFirstPage);
      setIsLastPage(isLastPage);
      setIsInterstitialPage(isInterstitial(page));
    };

    const handleValueChanged = (sender: SurveyModel, question: any) => {
      Analytics.track('Answer selected', {
        survey_name: surveyInstance.title,
        question_title: question.question.title,
        question_name: question.name,
        question_value: question.value,
        pageNumber: sender.currentPageNo
      });
      SurveyData.save(sender);
    };

    const handleComplete = () => {
      Analytics.track('Survey Completed', {
        data: surveyInstance.data,
      });
      setCurrentQuestionIndex(surveyInstance.pages.length);
    };

    surveyInstance.fromJSON(surveyConfigJson);
    SurveyData.restore(surveyInstance);
    setInitialSurveyState(surveyInstance); //set the initial Survey State

    surveyInstance.onValueChanged.add(handleValueChanged);
    surveyInstance.onCurrentPageChanged.add(handleCurrentPageChanged);
    surveyInstance.onComplete.add(handleComplete);

    return () => {
      surveyInstance.onValueChanged.remove(handleValueChanged);
      surveyInstance.onCurrentPageChanged.remove(handleCurrentPageChanged);
      surveyInstance.onComplete.remove(handleComplete);
    };
  }, [surveyConfigJson]);

  return (
    <div className="container">
      <div style={{paddingBottom: '10px'}}>
        <RiseHeader
          backButton={!isFirstPage}
          onBackClick={() => {
            surveyInstance?.prevPage();
          }}
        />
      </div>
      <div style={{paddingBottom: '24px'}}>
        <SegmentedProgressBar
          currentQuestionIndex={currentQuestionIndex}
          totalQuestions={surveyInstance?.pages.length ?? 0}
        />
      </div>

      {surveyInstance ? <Survey model={surveyInstance} /> : null}

      <RiseSurveyControls mode={buttonMode} />
    </div>
  );
};
