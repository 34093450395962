/**
 * The type of a question in the Rise survey
 * Define questions here to use in Surveys
 */

import {RiseQuestion} from './utils';

export const q_age: RiseQuestion = {
  type: 'radiogroup',
  name: 'age',
  title: 'Sleep needs vary by age. How old are you?',
  choices: ['18-23', '24-29', '30-39', '40-49', '50-59', '60+'],
};
export const q_current_quality: RiseQuestion = {
  type: 'radiogroup',
  name: 'current_quality',
  title:
    'Research shows that sleep quality impacts daily energy levels. How would you rate your current sleep quality?',
  choices: [
    'I sleep like a baby',
    'I toss and turn a bit',
    'I struggle to fall asleep',
    'I wake up multiple times at night',
    'I feel exhausted in the morning',
  ],
};

export const q_goal: RiseQuestion = {
  type: 'radiogroup',
  name: 'goal',
  title:
    'Sleep science research suggests strategies to improve sleep quality. What is your primary goal for better sleep?',
  choices: [
    'Fall asleep faster',
    'Stay asleep through the night',
    'Improve sleep quality',
    'Wake up feeling refreshed',
    'Reduce sleep debt',
  ],
};

export const q_wake_up: RiseQuestion = {
  type: 'radiogroup',
  name: 'wake_up',
  title:
    'Learn insights from sleep specialists on ways to wake up refreshed. How do you feel when you wake up in the morning?',
  choices: [
    'I jump out of bed ready to conquer the day',
    'I feel groggy and sluggish',
    'I hit snooze multiple times',
    'I need a crane to lift me out of bed',
    'I wake up feeling refreshed',
  ],
};
export const q_sleep_patterns: RiseQuestion = {
  type: 'radiogroup',
  name: 'sleep_patterns',
  title:
    'Research about sleep patterns shows the importance of consistency. When did you typically wake up in the last 2 weeks?',
  choices: ['6-7 AM', '7-8 AM', '8-9 AM', '9-10 AM', 'After 10 AM'],
};

export const q_bedtime_routines: RiseQuestion = {
  type: 'radiogroup',
  name: 'bedtime_routines',
  title:
    'Obtain insights from sleep specialists on bedtime routines. When did you typically go to bed in the last 2 weeks?',
  choices: ['Before 10 PM', '10-11 PM', '11 PM-12 AM', 'After 12 AM', 'Varied'],
};

export const q_sleep_consistency: RiseQuestion = {
  type: 'radiogroup',
  name: 'sleep_consistency',
  title:
    'Guidance based on studies about sleep consistency. If you had complete freedom, when would you ideally wake up every day?',
  choices: ['6-7 AM', '7-8 AM', '8-9 AM', '9-10 AM', 'After 10 AM'],
};

export const q_weekend_wake_up: RiseQuestion = {
  type: 'radiogroup',
  name: 'weekend_wake_up',
  title:
    'Research into sleep routines highlights weekend impacts. Do you usually keep the same wake-up time on weekends?',
  choices: ['Yes, I stick to my schedule', 'I sleep in a bit', 'I wake up earlier', 'My weekend wake-up varies'],
};

export const q_bedroom_temperature: RiseQuestion = {
  type: 'radiogroup',
  name: 'bedroom_temperature',
  title:
    'Insights from sleep specialists on sleep environment. How would you describe the temperature of your bedroom while sleeping?',
  choices: ['Cool and comfortable', 'Warm and cozy', 'Varies throughout the night', 'Too hot or too cold'],
};

export const q_noise_level: RiseQuestion = {
  type: 'radiogroup',
  name: 'noise_level',
  title:
    'Discover research into sleep environments and noise levels. How would you describe the noise level in your bedroom while sleeping?',
  choices: ['Silent', 'Some background noise', 'Noisy', 'Varies'],
};

export const q_light_level: RiseQuestion = {
  type: 'radiogroup',
  name: 'light_level',
  title:
    'Receive guidance based on studies about sleep environment. How would you describe the amount of light in your bedroom while sleeping?',
  choices: ['Pitch black', 'Dim light', 'Some light', 'Bright light'],
};

export const q_dinner_habits: RiseQuestion = {
  type: 'radiogroup',
  name: 'dinner_habits',
  title: 'Find insights from sleep specialists on dinner habits. What does your typical dinner consist of?',
  choices: [
    'Balanced meal with protein and veggies',
    'Fast food or takeout',
    'Light snack',
    'Skipping dinner',
    'Varies',
  ],
};

export const q_dinner_timing: RiseQuestion = {
  type: 'radiogroup',
  name: 'dinner_timing',
  title: 'Research into dinner timing and sleep. When do you usually have dinner?',
  choices: ['Around 6 PM', 'Between 6-7 PM', 'Between 7-8 PM', 'After 8 PM', 'Varies'],
};

export const q_hydration: RiseQuestion = {
  type: 'radiogroup',
  name: 'hydration',
  title: 'Insights from sleep specialists on hydration. How much water do you typically drink in a day?',
  choices: ['Less than 1 liter', '1-2 liters', '2-3 liters', 'More than 3 liters', 'Varies'],
};

export const q_caffeine_intake: RiseQuestion = {
  type: 'radiogroup',
  name: 'caffeine_intake',
  title:
    'Guidance based on studies about caffeine and sleep. How often do you consume coffee or caffeinated drinks in a day?',
  choices: ['None', '1 cup', '2 cups', '3 cups', 'More than 3 cups'],
};

export const q_job_type: RiseQuestion = {
  type: 'radiogroup',
  name: 'job_type',
  title: 'Insights from sleep specialists on daily routines. What is your job type?',
  choices: ['Office job', 'Physical labor', 'Remote work', 'Shift work'],
};

export const q_sports_activity: RiseQuestion = {
  type: 'radiogroup',
  name: 'sports_activity',
  title: 'Research into shift work and physical activity. Do you participate in any sports activities?',
  choices: ['Yes, regularly', 'Occasionally', 'No, not currently', 'Varies'],
};

export const q_screen_time: RiseQuestion = {
  type: 'radiogroup',
  name: 'screen_time',
  title:
    'Guidance based on studies about screen time. How many hours do you spend on screens (phone, computer, TV) per day?',
  choices: ['Less than 2 hours', '2-4 hours', '4-6 hours', 'More than 6 hours', 'Varies'],
};
export const q_morning_energy: RiseQuestion = {
  type: 'radiogroup',
  name: 'morning_energy',
  title: 'Insights from sleep specialists on energy levels. How do you feel within the first hour of waking up?',
  choices: ['Energetic and alert', 'Slightly groggy', 'Need time to wake up', 'Still half asleep', 'Ready for a nap'],
};

export const q_post_lunch_energy: RiseQuestion = {
  type: 'radiogroup',
  name: 'post_lunch_energy',
  title: 'Research into post-lunch energy levels. How do you feel after lunch?',
  choices: ['Energized and focused', 'Slightly sluggish', 'In need of a caffeine boost', 'Ready for a nap', 'Varies'],
};

export const q_evening_energy: RiseQuestion = {
  type: 'radiogroup',
  name: 'evening_energy',
  title:
    'Guidance based on studies about evening energy levels. When do you start feeling less energetic in the evening?',
  choices: ['Early evening', 'Late evening', 'After dinner', 'Before bedtime', 'Varies'],
};
export const q_calendar_flexibility: RiseQuestion = {
  type: 'radiogroup',
  name: 'calendar_flexibility',
  title: 'How flexible are you in adjusting your calendar planning based on your energy peaks?',
  choices: ['Very flexible', 'Somewhat flexible', 'Not very flexible', 'Not flexible at all'],
};

export const q_bedtime_obstacles: RiseQuestion = {
  type: 'radiogroup',
  name: 'bedtime_obstacles',
  title: 'Are there any obstacles that could affect your bedtime routine?',
  choices: ['Noise disturbances', 'Screen time', 'Stress or anxiety', 'Room temperature', 'No obstacles'],
};
export const q_gender: RiseQuestion = {
  type: 'radiogroup',
  name: 'gender',
  title: 'Select your gender.',
  choices: ['Male', 'Female', 'Non-Binary', 'Prefer not to say'],
};
