import React from 'react';
import '../../interstitialBase.css';
import ProgressChart from './ProgressChart';
import RiseSurveyInterstitialControls from '../../../../components/RiseSurveyControls/RiseSurveyInterstitialControls';
import {RiseInterstitialProps} from '../../RiseInterstitialBase';

const InterstitialProgress: React.FC<RiseInterstitialProps> = () => {
  return (
    <div className="interstitial ">
      <h1>Sleep Debt Reduction Progress</h1>
      <div className="card-container" style={{paddingLeft: '3px', paddingRight: '3px'}}>
        <div style={{paddingLeft: '12px'}} className="card-title">
          Enhance your sleep quality and daily energy
        </div>
        <div style={{paddingLeft: '12px', paddingRight: '12px'}}>
          <ProgressChart />
        </div>
        <div
          style={{
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingTop: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '15px',
            color: 'rgb(97, 96, 98)',
          }}
        >
          <span>See how personalized adjustments have accelerated improvements in energy levels for Rise users.</span>
        </div>
      </div>
      <RiseSurveyInterstitialControls />
    </div>
  );
};

export default InterstitialProgress;
