import React, {useEffect, useState} from 'react';
import ButtonSolid from '../Buttons/ButtonSolid';
import {PageModel} from 'survey-core';
import {SurveyData} from '../RiseSurvey/SurveyData';
import {surveyInstance} from '../RiseSurvey/RiseSurvey';
import Analytics from '../../utils/Analytics';

const getNextDelayMs = (page: PageModel) => {
  //@ts-expect-error We need to access the custom `riseProperties` property, and this is the only way I know how.
  const {delayOnNext} = page?.jsonObj?.riseProperties ?? {};
  return delayOnNext;
};

export const CompleteButton: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const page = surveyInstance.currentPage;
  const nextDelayMs = getNextDelayMs(page);
  const isInterstitial = page.isInterstitial;
  const [disabled, setDisabled] = useState(isInterstitial);
  useEffect(() => {
    const handleValueChanged = (sender: any) => {
      const allQuestionsAnswered = sender.currentPage.questions.every((question: any) => question.value !== undefined);
      setDisabled(!allQuestionsAnswered);
    };

    surveyInstance.onValueChanged.add(handleValueChanged);
    return () => {
      surveyInstance.onValueChanged.remove(handleValueChanged);
    };
  }, []);

  return (
    <ButtonSolid
      title={'Complete'}
      loading={loading}
      disabled={disabled}
      onClick={() => {
        const page = surveyInstance.currentPage;
        Analytics.track('Complete clicked', {
          survey_name: surveyInstance.title,
          question_title: page.questions[0].title,
          question_name: page.questions[0].name,
          question_value: page.questions[0].value,
        });
        if (nextDelayMs > 0) {
          setDisabled(true);
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
            surveyInstance?.completeLastPage();
            if (surveyInstance) {
              SurveyData.save(surveyInstance);
            }
          }, nextDelayMs);
        } else {
          surveyInstance?.completeLastPage();
          if (surveyInstance) {
            SurveyData.save(surveyInstance);
          }
        }
      }}
    />
  );
};
