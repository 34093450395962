import React from 'react';
import './Button.css';

export type ButtonBaseProps = {
  disabled?: boolean;
  title: string;
  onClick?: () => void;
  loading?: boolean;
  className?: string;
};

export default function ButtonBase({title, onClick, disabled = false, loading, className}: ButtonBaseProps) {
  return (
    <button className={`rise-btn-base ${className}`} onClick={onClick} disabled={disabled || loading}>
      {loading ? <div className="spinner" /> : title}
    </button>
  );
}
