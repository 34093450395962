import {useNavigate} from 'react-router-dom';
import ButtonSolid from '../../components/Buttons/ButtonSolid';

import {SurveyData} from '../../components/RiseSurvey/SurveyData';
import {surveyInstance} from '../../components/RiseSurvey/RiseSurvey';
import {BasicAB} from '../../utils/BasicAB';

export default function DemoScreen() {
  const navigate = useNavigate();

  const go = (path: string) => {
    SurveyData.clear();
    surveyInstance.clear();
    navigate(path);
  };

  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        fontSize: '200px',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px'}}>
        <ButtonSolid
          onClick={() => {
            BasicAB.resetAll();
          }}
          title="🗑️ Clear Experiments"
        />
        <ButtonSolid
          onClick={() => {
            SurveyData.clear();
            surveyInstance.clear();
          }}
          title="🗑️ Clear Survey Data"
        />

        <ButtonSolid onClick={() => go('/v1')} title="V1" />
        <ButtonSolid onClick={() => go('/v2-rise-variant')} title="V2 Rise Variant" />

        <ButtonSolid onClick={() => go('/demo-interstitials')} title="Interstitials" />
        <ButtonSolid onClick={() => go('/demo-short')} title="Short" />
        <ButtonSolid onClick={() => go('/demo-dynamic')} title="Dynamic" />
        <ButtonSolid onClick={() => go('/testimonials-oct-2024')} title="Testimonial Credibility" />
        <ButtonSolid onClick={() => go('/checkout')} title="Checkout" />
      </div>
    </div>
  );
}
