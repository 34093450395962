import React from 'react';
import '../../interstitialBase.css';
import StaticSleepDebtChart from './StaticSleepDebtChart';
import {addWeeks, format} from 'date-fns';
import {RiseInterstitialProps} from '../../RiseInterstitialBase';
import RiseSurveyInterstitialControls from '../../../../components/RiseSurveyControls/RiseSurveyInterstitialControls';

const ProgressInterstitial: React.FC<RiseInterstitialProps> = () => {
  const now = format(new Date(), 'MMM dd');
  const threeWeeksLater = format(addWeeks(new Date(), 3), 'MMM dd');
  return (
    <div className="interstitial ">
      <h1>Sleep Debt Reduction</h1>
      <div className="card-container" style={{paddingLeft: '3px', paddingRight: '3px'}}>
        <div style={{paddingLeft: '12px'}} className="card-title">
          Reduce the sleep debt just in 3 weeks
        </div>
        <StaticSleepDebtChart />
        <div
          style={{
            paddingLeft: '10px',
            paddingRight: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '13px',
            color: 'rgb(97, 96, 98)',
            marginTop: '-4px',
          }}
        >
          <span>{now}</span>
          <span>{threeWeeksLater}</span>
        </div>
      </div>
      <div>
        <p>See the positive impact of personalized adjustments on reducing sleep debt and boosting energy levels.</p>
      </div>
      <RiseSurveyInterstitialControls />
    </div>
  );
};

export default ProgressInterstitial;
