import {PageModel} from 'survey-core';
import {QUESTION_RISE_INTERSTITIAL, RiseInterstitialProps} from './RiseInterstitialBase';

/**
 * Creates an interstitial page
 *
 */
type Options = {
  name: string;
  isQuestion?: boolean;
  component: React.FC<RiseInterstitialProps & Record<string, any>>;
  visibleIf?: string;
};
export const interstitial = ({name, component, visibleIf}: Options) => {
  return {
    visibleIf,
    elements: [
      {
        type: QUESTION_RISE_INTERSTITIAL,
        titleLocation: 'hidden',
        name,
        riseComponent: component,
      },
    ],
  };
};

/**
 * Looks for any questions on the page with the type of QUESTION_RISE_INTERSTITIAL
 */
export const isInterstitial = (page: PageModel) => {
  return page?.questions.some((q: any) => q?.jsonObj?.type === QUESTION_RISE_INTERSTITIAL);
};
