import React from 'react';
import hero from '../../assets/good-hands.webp';
import '../../interstitialBase.css';
import RiseSurveyInterstitialControls from '../../../../components/RiseSurveyControls/RiseSurveyInterstitialControls';
import {RiseInterstitialProps} from '../../RiseInterstitialBase';

const SuccessInterstitial: React.FC<RiseInterstitialProps> = () => {
  return (
    <div className="interstitial centerText">
      <div className="hero">
        <img src={hero} style={{width: '100%'}} />
      </div>
      <div>
        <h1>Success Stories</h1>
        <p>Over 320,000 users achieved improved energy levels through reduced sleep debt.</p>
        <p>Join the success!</p>
      </div>
      <RiseSurveyInterstitialControls />
    </div>
  );
};
export default SuccessInterstitial;
