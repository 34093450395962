import React from 'react';
import hero from './hero.png';
import '../../interstitialBase.css';
import RiseSurveyInterstitialControls from '../../../../components/RiseSurveyControls/RiseSurveyInterstitialControls';
import {RiseInterstitialProps} from '../../RiseInterstitialBase';

const SportsInterstitial: React.FC<RiseInterstitialProps> = () => {
  return (
    <div className="interstitial centerText">
      <img src={hero} style={{width: '100%'}} alt="NFL" />
      <RiseSurveyInterstitialControls />
    </div>
  );
};
export default SportsInterstitial;
