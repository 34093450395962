import img_editors_choice from '../../Interstitials/assets/editors-choice.png';
import img_female_hero from '../../Interstitials/assets/female-user.jpg';
import img_thumbs_up from '../../Interstitials/assets/good-hands.webp';
import img_male_hero from '../../Interstitials/assets/male-user.jpg';
import img_rise_twinkles from '../../Interstitials/assets/rise-logo-with-twinkles.png';
import img_sleep_twinkles from '../../Interstitials/assets/sleep-icon-with-twinkles.png';
import AnalyzeInterstitial from '../../Interstitials/screens/AnalyzeInterstitial/AnalyzeInterstitial';
import BoostedEnergyInterstitial from '../../Interstitials/screens/BoostedEnergyInterstitial/BoostedEnergyInterstitial';
import ProgressInterstitial from '../../Interstitials/screens/ProgressInterstitial/ProgressInterstitial';
import RiseInterstitialTemplate from '../../Interstitials/screens/RiseInterstitialTemplate/RiseInterstitialTemplate';
import SleepDebtInterstitial from '../../Interstitials/screens/SleepDebtInterstitial/SleepDebtInterstitial';
import SportsInterstitial from '../../Interstitials/screens/SportsInterstitial/SportsInterstitial';
import {interstitial} from '../../Interstitials/utils';
import * as rise from '../riseQuestionsV2';
import {page} from '../utils';
import {survey_config_base} from './__survey_config_base';
function getRandomDelay() {
  return Math.floor(Math.random() * (1000 - 200 + 1)) + 200;
}

export const survey_config_web_testimonial_credibility_oct_2024 = {
  ...survey_config_base,
  title: 'Web Testimonial Credibility Oct 2024',
  pages: [
    page(rise.q_age, {delayDuration: getRandomDelay()}),
    page(rise.q_gender, {delayDuration: getRandomDelay()}),
    page(rise.q_current_quality, {delayDuration: getRandomDelay()}),
    page(rise.q_goal, {delayDuration: getRandomDelay()}),
    interstitial({
      name: 'success_stories_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_thumbs_up} style={{maxHeight: '200px'}} />}
          bodyText={
            '<div class="bold text-x-large leading-tight">Success<br />Stories</div><div class="text-large leading-loose">Join over 190,000 users improving their energy through reduced Sleep Debt</div>'
          }
          continueButtonTitle="Next"
        />
      ),
    }),
    page(rise.q_wake_up, {delayDuration: getRandomDelay()}),
    interstitial({
      name: '4_out_of_5_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_rise_twinkles} style={{maxHeight: '200px'}} />}
          bodyText={
            '<div class="bold text-x-large leading-tight">4 out of 5</div><div class="text-large leading-loose">users say RISE has helped them manage their sleep and energy.</div>'
          }
          continueButtonTitle="Next"
        />
      ),
    }),
    page(rise.q_bedtime_routines, {delayDuration: getRandomDelay()}),
    page(rise.q_sleep_consistency, {delayDuration: getRandomDelay()}),
    page(rise.q_weekend_wake_up, {delayDuration: getRandomDelay()}),
    page(rise.q_sleep_patterns, {delayDuration: getRandomDelay()}),
    interstitial({
      name: 'nights_tracked_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_sleep_twinkles} style={{maxHeight: '200px'}} />}
          bodyText={
            '<div class="text-large leading-loose">You’re in good hands!<br/>RISE has tracked <div class="bold text-x-large leading-tight">1.7 Billion</div> nights of sleep.</div>'
          }
          continueButtonTitle="Next"
        />
      ),
    }),
    page(rise.q_bedroom_temperature, {delayDuration: getRandomDelay()}),
    page(rise.q_noise_level, {delayDuration: getRandomDelay()}),
    page(rise.q_light_level, {delayDuration: getRandomDelay()}),
    interstitial({name: 'sleep_debt_interstitial', component: SleepDebtInterstitial}),
    page(rise.q_dinner_habits, {delayDuration: getRandomDelay()}),
    page(rise.q_dinner_timing, {delayDuration: getRandomDelay()}),
    page(rise.q_hydration, {delayDuration: getRandomDelay()}),
    page(rise.q_caffeine_intake, {delayDuration: getRandomDelay()}),
    page(rise.q_job_type, {delayDuration: getRandomDelay()}),
    page(rise.q_sports_activity, {delayDuration: getRandomDelay()}),
    page(rise.q_screen_time, {delayDuration: getRandomDelay()}),
    interstitial({name: 'progress_interstitial', component: ProgressInterstitial}),
    page(rise.q_morning_energy, {delayDuration: getRandomDelay()}),
    //This will show `Male` gender
    interstitial({
      visibleIf: "{gender} == 'Male'",
      name: 'testimonial_male_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_male_hero} style={{maxHeight: '200px'}} />}
          headerText="Prioritizing sleep can be hard. RISE makes it easy."
          bodyText="<div class='text-large italic'>“In only a few weeks, I found myself more focused, energetic, and productive at work.” <div class='text-medium' style='padding-top:8px;'>-Chase</div></div>"
          continueButtonTitle="Next"
        />
      ),
    }),
    //This will show for any gender not `Male`
    interstitial({
      visibleIf: "{gender} != 'Male'",
      name: 'testimonial_female_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_female_hero} style={{maxHeight: '200px'}} />}
          headerText="RISE makes it easy to turn your sleep data into useful insights."
          bodyText="<div class='text-large italic'>“I’m kinder to myself when I feel down or less energized. I know how to get my energy back when I need it.” <div class='text-medium' style='padding-top:8px;'>-Morgane</div></div>"
          continueButtonTitle="Next"
        />
      ),
    }),
    page(rise.q_post_lunch_energy, {delayDuration: getRandomDelay()}),
    page(rise.q_evening_energy, {delayDuration: getRandomDelay()}),
    interstitial({name: 'boosted_energy_interstitial', component: BoostedEnergyInterstitial}),
    page(rise.q_calendar_flexibility, {delayDuration: getRandomDelay()}),
    page(rise.q_bedtime_obstacles, {delayDuration: getRandomDelay()}),
    interstitial({name: 'analyze_interstitial', component: AnalyzeInterstitial}),
    interstitial({name: 'sports_interstitial', component: SportsInterstitial}),
    interstitial({
      name: 'app_credibility_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_editors_choice} style={{maxHeight: '220px'}} />}
          bodyText='<div class="text-x-large"><div class="bold">7.94 million</div>downloads</div>'
          continueButtonTitle="Next"
        />
      ),
    }),
  ],
};
