import {interstitial} from '../../Interstitials/utils';
import {page} from '../utils';
import {survey_config_base} from './__survey_config_base';
import * as dynamicQuestionExample from '../dynamicQuestionExample';
import RiseInterstitialTemplate from '../../Interstitials/screens/RiseInterstitialTemplate/RiseInterstitialTemplate';
import imageMaleHero from '../../Interstitials/assets/male-user.png';
import imageFemaleHero from '../../Interstitials/assets/female-user.png';
import imageSocialProofStars from '../../Interstitials/assets/Proof.png';

export const survey_config_demo_dynamic = {
  ...survey_config_base,
  title: 'Demo Dynamic',
  // Create a calculated value based on the expression (https://surveyjs.io/form-library/documentation/design-survey/conditional-logic#calculated-values)
  // This can then be referenced by it's "name" in the question `title`
  calculatedValues: [
    {
      name: 'gendered_people_group',
      expression: "iif({gender} == 'Male', 'Men', iif({gender} == 'Female', 'Women', 'People'))",
    },
  ],

  pages: [
    page(dynamicQuestionExample.q_gender),
    //The questions will change based on the selected gender
    page(dynamicQuestionExample.q_dynamic_based_on_gender),
    //This will show for any gender not `Male`
    interstitial({
      name: 'dynamic_example2',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={imageMaleHero} style={{maxHeight: '200px'}} />}
          secondaryImage={<img src={imageSocialProofStars} style={{maxHeight: '44px'}} />}
          headerText="Optional headline that corresponds to content"
          bodyText="<quote>“This app is the reason I can manage my energy during the day and sleep when I need to”.</quote>"
          continueButtonTitle="Go on!"
        />
      ),
      visibleIf: "{gender} == 'Male'",
    }),
    //This will show for any gender not `Male`
    interstitial({
      name: 'dynamic_example2',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={imageFemaleHero} style={{maxHeight: '200px'}} />}
          headerText="Optional headline that corresponds to content"
          bodyText="<quote>“I’m kinder to myself when I feel down or less energized. I know how to get my energy back when I need it.” <br/><br/> -Michelle B.</quote>"
        />
      ),
      visibleIf: "{gender} != 'Male'",
    }),

    //This page will only be shown if the user selected "Male" in the first question
    page(dynamicQuestionExample.q_dynamic_only_men),
    //Everyone will see this
    page(dynamicQuestionExample.q_cats),
  ],
};

/**
 * 1. We can use an expression to create custom text or values based on previous answered questions.
 * example:  In a gender question with options like `Male`, `Female`, `Non-Binary`, and `Other`, we can create a value that translates it to "Men", "Women", and "People" for use in the text of other questions.
 *
 * 2. We can dynamically show/hide individual response options based on the value (or combined values) of other question responses.
 *
 * 3. We can dynamically show/hide entire pages based on the value (or combined values) of other question responses.
 *
 * 4. We can skip to a different question or complete the survey based on a selected value.  E.g.  If the user selected `Not a Student` we could skip the entire section of student questions
 * Or we could complete the survey early based on some selected response.
 *
 * There is a number of common built in functions (like if/then, basic math, date/time calculations) but we can also create our own functions too if necessary.
 *
 * Source: https://surveyjs.io/form-library/documentation/design-survey/conditional-logic#supported-operators
 *
 */
