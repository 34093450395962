import {interstitial} from '../../Interstitials/utils';
import * as rise from '../riseQuestions';
import {survey_config_base} from './__survey_config_base';
import {page} from '../utils';
import SuccessInterstitial from '../../Interstitials/screens/SuccessInterstitial/SuccessInterstitial';
import SleepDebtInterstitial from '../../Interstitials/screens/SleepDebtInterstitial/SleepDebtInterstitial';
import AnalyzeInterstitial from '../../Interstitials/screens/AnalyzeInterstitial/AnalyzeInterstitial';
import BoostedEnergyInterstitial from '../../Interstitials/screens/BoostedEnergyInterstitial/BoostedEnergyInterstitial';
import ProgressInterstitial from '../../Interstitials/screens/ProgressInterstitial/ProgressInterstitial';
import SportsInterstitial from '../../Interstitials/screens/SportsInterstitial/SportsInterstitial';

function getRandomDelay() {
  return Math.floor(Math.random() * (1000 - 200 + 1)) + 200;
}

export const survey_config_runwayer_july_2024 = {
  ...survey_config_base,
  title: 'V0 July 2024',
  pages: [
    page(rise.q_age, {delayDuration: getRandomDelay()}),
    page(rise.q_current_quality, {delayDuration: getRandomDelay()}),
    page(rise.q_goal, {delayDuration: getRandomDelay()}),
    page(rise.q_wake_up, {delayDuration: getRandomDelay()}),
    page(rise.q_sleep_patterns, {delayDuration: getRandomDelay()}),
    interstitial({name: 'success_interstitial', component: SuccessInterstitial}),
    page(rise.q_bedtime_routines, {delayDuration: getRandomDelay()}),
    page(rise.q_sleep_consistency, {delayDuration: getRandomDelay()}),
    page(rise.q_weekend_wake_up, {delayDuration: getRandomDelay()}),
    page(rise.q_bedroom_temperature, {delayDuration: getRandomDelay()}),
    page(rise.q_noise_level, {delayDuration: getRandomDelay()}),
    page(rise.q_light_level, {delayDuration: getRandomDelay()}),
    interstitial({name: 'sleep_debt_interstitial', component: SleepDebtInterstitial}),
    page(rise.q_dinner_habits, {delayDuration: getRandomDelay()}),
    page(rise.q_dinner_timing, {delayDuration: getRandomDelay()}),
    page(rise.q_hydration, {delayDuration: getRandomDelay()}),
    page(rise.q_caffeine_intake, {delayDuration: getRandomDelay()}),
    page(rise.q_job_type, {delayDuration: getRandomDelay()}),
    page(rise.q_sports_activity, {delayDuration: getRandomDelay()}),
    page(rise.q_screen_time, {delayDuration: getRandomDelay()}),
    interstitial({name: 'progress_interstitial', component: ProgressInterstitial}),
    page(rise.q_morning_energy, {delayDuration: getRandomDelay()}),
    page(rise.q_post_lunch_energy, {delayDuration: getRandomDelay()}),
    page(rise.q_evening_energy, {delayDuration: getRandomDelay()}),
    interstitial({name: 'boosted_energy_interstitial', component: BoostedEnergyInterstitial}),
    page(rise.q_calendar_flexibility, {delayDuration: getRandomDelay()}),
    page(rise.q_bedtime_obstacles, {delayDuration: getRandomDelay()}),
    interstitial({name: 'analyze_interstitial', component: AnalyzeInterstitial}),
    interstitial({name: 'sports_interstitial', component: SportsInterstitial}),
  ],
};
