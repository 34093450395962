import Environment from '../utils/Environment';
import {PaymentRequestPaymentMethodEvent, Stripe, StripeElements} from '@stripe/stripe-js';
import {validateCreditCard} from './stripe';
import Analytics from '../utils/Analytics';
import ReactPixel from 'react-facebook-pixel';
import * as Sentry from '@sentry/react';
import {PlanInfo} from '../utils/stripePlans';

export const BASE_URL = 'https://api.risesci.com';

// This defaults to "production"
// the issue is that if this is misconfigured on production and test is the default, then users will get Rise for free
const environment = Environment.getVar('REACT_APP_ENV') ?? 'production';

export const createCheckoutDetails = async (): Promise<{client_secret: string | undefined}> => {
  const endpoint = `${BASE_URL}/api/stripe-setup-checkout`;

  try {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const response = await fetch(endpoint, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        env: environment,
      }),
    });
    return await response.json();
  } catch (error) {
    Sentry.captureException(error);
    Analytics.track('Stripe Setup Checkout Error', {error});
    return {client_secret: undefined};
  }
};

export const completeCheckout = async (
  plan: PlanInfo,
  payment_method_id: string,
  email: string | undefined = undefined,
): Promise<{subscription: string | null; error: string | null}> => {
  const endpoint = `${BASE_URL}/api/stripe-dynamic-checkout`;

  Analytics.track('Stripe Checkout Started', {...plan, payment_method_id});
  try {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const body: {env: string; payment_method_id: string; code: string; email?: string} = {
      env: environment,
      payment_method_id,
      code: plan.id,
    };

    if (email) {
      Analytics.track('Platform Pay Email Captured', {email});
      body.email = email;
    }

    const response = await fetch(endpoint, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    });
    const {subscription} = await response.json();
    Analytics.track('Stripe Checkout Success', {subscription, payment_method_id, ...plan});
    Analytics.track('PaymentAccepted');
    ReactPixel.track('Purchase', {currency: plan.currency.toUpperCase(), value: plan.introPrice});
    return {error: null, subscription};
  } catch (error) {
    Analytics.track('Stripe Checkout Failure', {
      error,
      ...plan,
    });
    return {error: 'Could not complete checkout. Please try again or use an alternate method.', subscription: null};
  }
};

/**
 * Perform a payment transaction with a credit card. Will return the id of a subscription to be used in the
 * account creation step or a user-facing error message.
 *
 * @async
 * @param {PlanInfo} plan - The plan associated with the payment.
 * @param {StripeElements} elements - The Stripe elements for card input.
 * @param {Stripe} stripe - The Stripe object for handling payments.
 * @param {string} clientSecret - The client secret associated with the transaction.
 * @returns {Promise<{subscription: string | null; error: string | null}>} - The result of the payment transaction, including the subscription ID and any user-facing error message.
 */
export const payWithCreditCard = async (
  plan: PlanInfo,
  elements: StripeElements | null,
  stripe: Stripe | null,
  clientSecret: string | undefined,
): Promise<{subscription: string | null; error: string | null}> => {
  Analytics.track('Credit Card Payment Started', {...plan});
  const {result, error} = await validateCreditCard(clientSecret, stripe, elements);

  if (result?.setupIntent?.payment_method) {
    const {subscription, error} = await completeCheckout(plan, result.setupIntent.payment_method as string);
    if (subscription) {
      Analytics.track(`Credit Card Success`, {...plan});
      return {subscription, error: null};
    } else {
      Analytics.track(`Credit Card Failure`, {error, ...plan});
      return {subscription: null, error: 'Could not complete checkout, please try again.'};
    }
  }

  if (!error) {
    // if error is present, Analytics will be reported in validateCreditCard method
    Analytics.track(`Credit Card Failure`, {error: 'payment_method missing', ...plan});
  }

  return {subscription: null, error: error ?? 'Could not complete checkout, please try again.'};
};

/**
 * Performs a payment using the platform pay method. Will return the id of a subscription to be used in the
 * account creation step or a user-facing error message.
 *
 * @param {PlanInfo} plan - The payment priceId.
 * @param {PaymentRequestPaymentMethodEvent} event - The payment method event object.
 * @returns {Promise<{subscription: Object|null, error: string|null}>} - The result of the payment.
 */
export const payWithPlatformPay = async (
  plan: PlanInfo,
  event: PaymentRequestPaymentMethodEvent,
): Promise<{subscription: string | null; error: string | null}> => {
  Analytics.track('Platform Pay Payment Started', {store: event.walletName, ...event, ...plan});

  const {paymentMethod, payerEmail} = event;

  const {subscription, error} = await completeCheckout(plan, paymentMethod.id, payerEmail);

  if (subscription) {
    Analytics.track('Platform Pay Success', {store: event.walletName, ...event, ...plan});
    return {subscription, error: null};
  } else {
    Analytics.track('Platform Pay Failure', {error, store: event.walletName, ...event, ...plan});
    return {subscription: null, error: 'Could not complete checkout, please try again.'};
  }
};

export const createAccount = async (
  email: string,
  subscription: string,
  flow = 'web-acquisition',
): Promise<{person_id: number | null; error: boolean}> => {
  const endpoint = `${BASE_URL}/api/runwayer-create-account`;

  Analytics.track('Account Creation Started', {flow, email, subscription});

  try {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const response = await fetch(endpoint, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        environment,
        flow,
        email,
        subscriptionId: subscription,
      }),
    });
    const {userId} = await response.json();

    Analytics.identify(userId);
    Analytics.track('Account Creation Success', {email, subscription});
    return {person_id: userId, error: false};
  } catch (error) {
    Analytics.track('Account Creation Failure', {error, email, subscription});
    return {person_id: null, error: true};
  }
};
