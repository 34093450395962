import Analytics from '../utils/Analytics';
const LOCAL_STORAGE_PREFIX = 'basic_ab_';

/**
 * BasicAB is a simple A/B testing library that allows you to determine which variant of an experiment a user should see.
 * @param experimentName - The name of the experiment to determine the variant for.
 * @param variants - An object where the keys are the variant names and the values are the weights of the variants.
 * @returns The name of the variant that the user should see.
 */
function determineValue(experimentName: string, variants: Record<string, number>) {
  if (Object.values(variants).some(weight => weight < 0)) {
    throw new Error('All variant weights must be non-negative');
  }

  if (Object.values(variants).reduce((acc, weight) => acc + weight, 0) !== 1) {
    throw new Error('The sum of all variant weights must be 1');
  }

  const key = LOCAL_STORAGE_PREFIX + experimentName.toLowerCase();
  const existingValue = localStorage.getItem(key) ?? null;

  if (existingValue) {
    return existingValue;
  }

  const randomNumber = Math.random();
  let cumulativeWeight = 0;

  for (const [variant, weight] of Object.entries(variants)) {
    cumulativeWeight += weight;
    if (randomNumber < cumulativeWeight) {
      localStorage.setItem(key, variant);
      Analytics.track('BasicAB Determined Value', {
        experimentName,
        variant,
      });

      return variant;
    }
  }

  // This line should never be reached if weights sum to 1
  throw new Error('Failed to determine a variant');
}

function reset(experimentName: string) {
  localStorage.removeItem(LOCAL_STORAGE_PREFIX + experimentName.toLowerCase());
}
/**
 * Removes all of the stored values for BasicAB
 */
function resetAll() {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && key.startsWith(LOCAL_STORAGE_PREFIX)) {
      localStorage.removeItem(key);
    }
  }
}

export const BasicAB = {
  determineValue,
  reset,
  resetAll,
};
