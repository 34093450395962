export type RiseQuestion = {
  type: string;
  name: string;
  title: string;
  visibleIf?: string;
  choices: (string | {value: string; visibleIf: string})[];
};

type PageOptions = {
  /** How long to show the spinner for before moving to the next page */
  delayDuration?: number;
};

export const page = (question: RiseQuestion, options: PageOptions = {}) => {
  return {
    elements: [question],
    riseProperties: {
      delayOnNext: options.delayDuration ?? 0,
    },
  };
};
