import React, {useEffect, useState} from 'react';
import '../../interstitialBase.css';
import AnimatedBar from './components/AnimatedBar';
import {AnimatedCounter} from './components/AnimatedCounter';
import EarlyBirdModal from './components/EarlyBirdModal';
import {RiseInterstitialProps} from '../../RiseInterstitialBase';
import RiseSurveyInterstitialControls from '../../../../components/RiseSurveyControls/RiseSurveyInterstitialControls';

const duration = 2500;
type BarRowProps = {
  title: string;
  duration: number;
  delay: number;
};
const BarRow = ({title, duration, delay}: BarRowProps) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '35px',
          fontSize: '15px',
          color: 'black',
        }}
      >
        <div>{title}</div>
        <div style={{fontWeight: 'bold'}}>
          <AnimatedCounter duration={duration} delay={delay} />
        </div>
      </div>
      <AnimatedBar duration={duration} delay={delay} />
    </div>
  );
};

const AnalyzingView = () => {
  return (
    <div>
      <h1 className="centerText">Let's analyze your answers</h1>
      <div>
        <BarRow title="Analyzing Profile" duration={duration} delay={0} />
      </div>
      <div style={{marginTop: '16px'}}>
        <BarRow title="Calculating sleep trends" duration={duration} delay={duration} />
      </div>
      <div style={{marginTop: '16px'}}>
        <BarRow title="Building the personalized plan" duration={duration} delay={duration * 2} />
      </div>

      <div
        style={{
          marginTop: '35px',
          display: 'flex',
          flexDirection: 'column',
          borderStyle: 'solid',
          borderRadius: '15px',
          borderWidth: '1px',
          borderColor: 'rgb(228, 228, 231)',
          padding: '16px',
          fontSize: '17px',
          color: 'black',
          textAlign: 'center',
        }}
      >
        An astonishing 95% felt that the app made them more productive at work.
      </div>
    </div>
  );
};
const FinalView = () => {
  return (
    <div>
      <h1 className="centerText">Personalized Energy Boost</h1>
      <p>
        Based on your preferences and habits, Rise is tailored to help you wake up feeling refreshed, combat sleep
        struggles, and align with your natural energy peaks.
      </p>
      <p>
        By tracking your sleep debt, optimizing your circadian rhythm, and offering actionable recommendations, Rise
        aims to enhance your daily energy levels and productivity.
      </p>
      <p>With personalized tips and insights, Rise is your bespoke plan for a revitalized routine.</p>
    </div>
  );
};

const AnalyzeInterstitial: React.FC<RiseInterstitialProps> = ({onValueSelect}) => {
  const [showModal, setShowModal] = useState(false);
  const [showFinal, setShowFinal] = useState(false);

  useEffect(() => {
    setTimeout(
      () => {
        setShowModal(true);
      },
      duration * 3 - 300,
    );
  }, []);
  return (
    <div>
      <div className="interstitial">{showFinal ? <FinalView /> : <AnalyzingView />}</div>
      {showModal ? (
        <EarlyBirdModal
          onEarlyBird={() => {
            onValueSelect?.('early_bird');
            setShowModal(false);
            setShowFinal(true);
          }}
          onNightOwl={() => {
            onValueSelect?.('night_owl');
            setShowModal(false);
            setShowFinal(true);
          }}
        />
      ) : null}
      {showFinal ? <RiseSurveyInterstitialControls /> : null}
    </div>
  );
};

export default AnalyzeInterstitial;
