import React from 'react';
import logo from '../../commonAssets/logo.svg';
import './RiseHeader.css';
import arrow from '../../commonAssets/arrow.svg';
import {useNavigate} from 'react-router-dom';
import {SurveyData} from '../RiseSurvey/SurveyData';
import {surveyInstance} from '../RiseSurvey/RiseSurvey';

type Props = {
  backButton?: boolean;
  onBackClick?: () => void;
};
export function RiseHeader({backButton = true, onBackClick}: Props) {
  const navigate = useNavigate();

  return (
    <div className="rise-header">
      <div className="rise-header-left">
        {backButton && onBackClick ? <img src={arrow} alt="Back" onClick={onBackClick} /> : null}
      </div>
      <div className="rise-header-center">
        <img
          src={logo}
          alt="Rise"
          onClick={() => {
            SurveyData.clear();
            surveyInstance.clear();
            navigate('/');
          }}
        />
      </div>
      <div className="rise-header-right" />
    </div>
  );
}
