import React, {useEffect, useState} from 'react';
import {Routes, Route} from 'react-router-dom';
import {RiseSurvey} from '../components/RiseSurvey/RiseSurvey';
import {survey_config_demo_interstitials} from '../screens/Surveys/surveyConfigs/survey_config_demo_interstitials';
import {survey_config_demo_short} from '../screens/Surveys/surveyConfigs/survey_config_demo_short';
import CheckoutScreen from '../screens/CheckoutScreen/CheckoutScreen';
import {survey_config_runwayer_july_2024} from '../screens/Surveys/surveyConfigs/survey_config_runwayer_july_2024';
import {survey_config_runwayer_sept_2024_rise_variant} from '../screens/Surveys/surveyConfigs/survey_config_runwayer_sept_2024_rise_variant';
import DemoScreen from '../screens/DemoScreen/DemoScreen';
import {usePageTracking} from './usePageTracking';
import AccountReady from '../screens/AccountReady/AccountReady';
import {BasicAB} from '../utils/BasicAB';
import {survey_config_demo_dynamic} from '../screens/Surveys/surveyConfigs/survey_config_demo_dynamic';
import {survey_config_web_testimonial_credibility_oct_2024} from '../screens/Surveys/surveyConfigs/survey_config_web_testimonial_credibility_oct_2024';

const AppRoutes: React.FC = () => {
  usePageTracking();
  const [ab, setAB] = useState<string | null>(null);

  useEffect(() => {
    // Note: this fires even when a different page is navigated to, so it might not be a good idea for a real AB
    // Note: :point_up: this useEffect might fix that. - caleb
    // setAB(BasicAB.determineValue('basic_ab_aa_v0', {a: 0.5, b: 0.5})); //previous AB/AA test from leon
    setAB(BasicAB.determineValue('web_testimonial_credibility', {control: 0.5, experiment: 0.5}));
  }, []);

  const randomSurveyConfig =
    ab === 'experiment'
      ? survey_config_web_testimonial_credibility_oct_2024
      : survey_config_runwayer_sept_2024_rise_variant;

  return (
    <Routes>
      {/* Randomized Survey between Runwayer and Rise Variant */}
      <Route path={`/`} element={<RiseSurvey surveyConfigJson={randomSurveyConfig} />} />
      <Route path={`/start`} element={<RiseSurvey surveyConfigJson={randomSurveyConfig} />} />

      {/* Handy Demo route */}
      <Route path="/demo" element={<DemoScreen />} />

      {/* Original Runwayer Survey */}
      <Route path="/v1" element={<RiseSurvey surveyConfigJson={survey_config_runwayer_july_2024} />} />

      {/* Runwayer Rise Variant */}
      <Route path="/v2" element={<RiseSurvey surveyConfigJson={survey_config_runwayer_sept_2024_rise_variant} />} />
      <Route path="/demo-interstitials" element={<RiseSurvey surveyConfigJson={survey_config_demo_interstitials} />} />
      <Route path="/demo-short" element={<RiseSurvey surveyConfigJson={survey_config_demo_short} />} />
      <Route path="/demo-dynamic" element={<RiseSurvey surveyConfigJson={survey_config_demo_dynamic} />} />
      <Route
        path="/testimonials-oct-2024"
        element={<RiseSurvey surveyConfigJson={survey_config_web_testimonial_credibility_oct_2024} />}
      />
      <Route path="/checkout" element={<CheckoutScreen />} />
      <Route path="/account-ready" element={<AccountReady />} />
    </Routes>
  );
};

export default AppRoutes;
