import React from 'react';

import '../../interstitialBase.css';
import './RiseInterstitialTemplate.css';
import RiseSurveyInterstitialControls from '../../../../components/RiseSurveyControls/RiseSurveyInterstitialControls';
import {RiseInterstitialProps} from '../../RiseInterstitialBase';

type Props = {
  headerText?: string | TrustedHTML;
  bodyText?: string | TrustedHTML;
  heroImage?: React.ReactNode;
  secondaryImage?: React.ReactNode;
  continueButtonTitle?: string;
} & RiseInterstitialProps;
const RiseInterstitialTemplate: React.FC<Props> = ({
  headerText,
  bodyText,
  heroImage,
  secondaryImage,
  continueButtonTitle,
}) => {
  return (
    <div id="dynamic_template_1">
      {headerText ? (
        <div className="headerText">
          <div className="heading1" dangerouslySetInnerHTML={{__html: headerText}} />
        </div>
      ) : null}
      {heroImage ? <div className="heroImage">{heroImage}</div> : null}
      {bodyText ? (
        <div className="bodyText">
          <div dangerouslySetInnerHTML={{__html: bodyText}} />
        </div>
      ) : null}
      {secondaryImage ? <div className="secondaryImage">{secondaryImage}</div> : null}
      <RiseSurveyInterstitialControls continueButtonTitle={continueButtonTitle} />
    </div>
  );
};
export default RiseInterstitialTemplate;
