import React from 'react';
import './AccountReady.css';
import playButton from './GooglePlay.png';
import appStoreButton from './AppStore.png';
import {useLocation} from 'react-router-dom';
import checkmark from './CircleCheckmark.svg';

/**
 * Pass in email address with the query param `email`
 */
const AccountReady = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');

  return (
    <div className="account-ready">
      <div className="checkmark-container">
        <img src={checkmark} alt="Checkmark" width={70} height={70} />
      </div>
      <div>
        <h2>Your account is ready!</h2>
        <div className="paragraph centered">Congratulations on starting your journey with "Rise".</div>
      </div>
      <div className="numbered-bullets">
        <div className="bullet-container">
          <div className="numbered-bullet">1</div>
          <div className="bullet-line" />
          <span>Download the app</span>
        </div>
        <div className="bullet-container">
          <div className="numbered-bullet">2</div>
          <div className="bullet-line" />
          <span>Login with {email?.length ? email : 'your email address'}</span>
        </div>
        <div className="bullet-container">
          <div className="numbered-bullet">3</div>
          <span>Enjoy your progress!</span>
        </div>
      </div>
      <div className="button-container">
        <div>Download the app now!</div>
        <div className="store-buttons">
        <a href="https://apps.apple.com/us/app/rise-sleep-tracker/id1453884781" className="store-button">
            <img src={appStoreButton} alt="Apple App Store" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.risesci.nyx" className="store-button">
            <img src={playButton} alt="Google Play Store" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default AccountReady;
