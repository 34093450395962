import {RiseQuestion} from './utils';
/**
 * The type of a question in the Rise survey
 * Define questions here to use in Surveys
 */

export const q_gender: RiseQuestion = {
  type: 'radiogroup',
  name: 'gender',
  title: 'What is your gender?',
  choices: ['Male', 'Female', 'Non-Binary', 'Other'],
};

export const q_dynamic_based_on_gender: RiseQuestion = {
  type: 'radiogroup',
  name: 'dynamic_text',
  // `gendered_people_group` is a calculated value based on the expression found in the survey config
  title: '{gendered_people_group} like to sleep.',
  // we can use `visibleIf` to show/hide choices based on the value of other questions
  choices: [
    'Default Option',
    {value: 'Men Option 1', visibleIf: "{gender} == 'Male'"},
    {value: 'Men Option 2', visibleIf: "{gender} == 'Male'"},
    {value: 'Women Option 1', visibleIf: "{gender} == 'Female'"},
    {value: 'Women Option 2', visibleIf: "{gender} == 'Female'"},
    {value: 'People Option 1', visibleIf: "{gender} != 'Male' && {gender} != 'Female'"},
    {value: 'People Option 2', visibleIf: "{gender} != 'Male' && {gender} != 'Female'"},
  ],
};

export const q_dynamic_only_men: RiseQuestion = {
  type: 'radiogroup',
  name: 'dynamic_text',
  title: '{gendered_people_group} like to sleep.',
  // We can use `visibleIf` to show/hide a whole question
  visibleIf: "{gender} == 'Male'",
  choices: ['Men Option 1', 'Men Option 2'],
};

export const q_cats: RiseQuestion = {
  type: 'radiogroup',
  name: 'cats',
  title: 'Do you like cats?',
  choices: ['Nope', 'Yep'],
};
