import AnalyzeInterstitial from '../../Interstitials/screens/AnalyzeInterstitial/AnalyzeInterstitial';
import SportsInterstitial from '../../Interstitials/screens/SportsInterstitial/SportsInterstitial';
import SuccessInterstitial from '../../Interstitials/screens/SuccessInterstitial/SuccessInterstitial';
import {interstitial} from '../../Interstitials/utils';
import * as rise from '../riseQuestions';
import {page} from '../utils';
import {survey_config_base} from './__survey_config_base';

export const survey_config_demo_short = {
  ...survey_config_base,
  title: 'Demo Short',
  pages: [
    page(rise.q_age, {delayDuration: 1000}),
    page(rise.q_current_quality),
    page(rise.q_goal),
    interstitial({name: 'success_interstitial', component: SuccessInterstitial}),
    page(rise.q_light_level),
    page(rise.q_bedtime_obstacles),
    interstitial({name: 'analyze_interstitial', component: AnalyzeInterstitial}),
    interstitial({name: 'sports_interstitial', component: SportsInterstitial}),
  ],
};
