import React from 'react';
import ButtonSolid from '../Buttons/ButtonSolid';
import {SurveyData} from '../RiseSurvey/SurveyData';
import {surveyInstance} from '../RiseSurvey/RiseSurvey';
import Analytics from '../../utils/Analytics';

type ContinueButtonProps = {
  title?: string;
};
export const ContinueButton: React.FC<ContinueButtonProps> = ({title}) => {
  return (
    <ButtonSolid
      title={title ?? 'Continue'}
      onClick={() => {
        const page = surveyInstance.currentPage;
        Analytics.track('Continue clicked', {
          survey_name: surveyInstance.title,
          question_title: page.questions[0].title,
          question_name: page.questions[0].name,
        });
        if (surveyInstance) {
          SurveyData.save(surveyInstance);
        }
        if (surveyInstance?.isLastPage) {
          surveyInstance?.completeLastPage();
        } else {
          surveyInstance?.nextPage();
        }
      }}
    />
  );
};
