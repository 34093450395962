import AnalyzeInterstitial from '../../Interstitials/screens/AnalyzeInterstitial/AnalyzeInterstitial';
import BoostedEnergyInterstitial from '../../Interstitials/screens/BoostedEnergyInterstitial/BoostedEnergyInterstitial';
import ProgressInterstitial from '../../Interstitials/screens/ProgressInterstitial/ProgressInterstitial';
import SleepDebtInterstitial from '../../Interstitials/screens/SleepDebtInterstitial/SleepDebtInterstitial';
import SportsInterstitial from '../../Interstitials/screens/SportsInterstitial/SportsInterstitial';
import SuccessInterstitial from '../../Interstitials/screens/SuccessInterstitial/SuccessInterstitial';
import {interstitial} from '../../Interstitials/utils';
import {survey_config_base} from './__survey_config_base';

export const survey_config_demo_interstitials = {
  ...survey_config_base,
  title: 'Runwayer July 2024',
  pages: [
    interstitial({name: 'success_interstitial', component: SuccessInterstitial}),
    interstitial({name: 'sleep_debt_interstitial', component: SleepDebtInterstitial}),
    interstitial({name: 'progress_interstitial', component: ProgressInterstitial}),
    interstitial({name: 'boosted_energy_interstitial', component: BoostedEnergyInterstitial}),
    interstitial({name: 'analyze_interstitial', component: AnalyzeInterstitial}),
    interstitial({name: 'sports_interstitial', component: SportsInterstitial}),
  ],
};
