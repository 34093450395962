import packageJson from '../../package.json';
import Environment from './Environment';
// import Environment from './Environment'
// import * as Sentry from '@sentry/react'

interface SegmentAnalytics {
  track: (eventName: string, payload?: object) => void;
  identify: (id: string | number | object, payload?: object) => void;
  // Add other methods from Segment's analytics as needed
}

declare global {
  interface Window {
    analytics: SegmentAnalytics;
  }
}

const metaPayload = () => {
  return {
    version: packageJson.version,
    environment: Environment.getVar('REACT_APP_ENV') ?? 'test',
    host: window.location.host,
    url: window.location.href,
    urlParams: window.location.search,
    userAgent: window.navigator.userAgent,
  };
};

function track(eventName: string, payload: object = {}) {
  const completePayload = {...metaPayload(), ...payload};
  const completeEventName = `[Web] ${eventName}`;
  window.analytics.track(completeEventName, completePayload);

  if (Environment.isTest) {
    logAnalytics(completeEventName, completePayload);
  }
}

const logAnalytics = (eventName: string, completePayload?: object) => {
  //TODO: Can we remove this in prod?
  console.log(`Sent to Amplitude: "${eventName}". Payload: ${JSON.stringify(completePayload, null, '\t')}`);
};

type IdentifyPayload = Record<string, any>;
type AnonymousIdentify = (payload: IdentifyPayload) => void;
type IdentifiedIdentify = (id: string | number, payload?: IdentifyPayload) => void;
const identify: IdentifiedIdentify & AnonymousIdentify = (
  idOrPayload: string | number | IdentifyPayload,
  payload?: IdentifyPayload,
) => {
  if (typeof idOrPayload === 'string' || typeof idOrPayload === 'number') {
    //id if known id
    window.analytics.identify(idOrPayload, {...metaPayload(), ...payload});
  } else {
    //payload if anonymous id
    window.analytics.identify({...metaPayload(), ...idOrPayload});
  }
};

const waitFor = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const analyticsIsReady = () => !Array.isArray(window.analytics);

/**
 * In some cases, we need to ensure that Segment is initialized before sending events
 * (redirecting back to the Checkout Success Page from Stripe Checkout is one example)
 * By default: waits 200 ms and retries 5 times.
 */
const onReady = async (analytics: () => void, retries = 5, waitInMs = 200): Promise<void> => {
  for (let i = 0; i < retries; i++) {
    if (analyticsIsReady()) {
      analytics();
      return;
    }
    await waitFor(waitInMs);
  }
  // Sentry.captureException(new Error("Analytics didn't initialize within window"));
};

const Analytics = {
  track,
  identify,
  onReady,
};
export default Analytics;
